import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Slider from "react-slick"
import Fade from "react-reveal/Fade"
import { isAndroid } from "react-device-detect"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../../components/core/seo"

import linkBackgroundImage from "../../images/graphic-link-bg-yearup.png"

import desktopA from "../../images/work/yearup/work-yearup-0-desktop.jpg"
import desktopB from "../../images/work/yearup/work-yearup-1-desktop.jpg"

import mobileA from "../../images/work/yearup/work-yearup-0-mobile.jpg"
import mobileB from "../../images/work/yearup/work-yearup-1-mobile.jpg"
import mobileC from "../../images/work/yearup/work-yearup-2-mobile.jpg"

import socialOG from "../../images/social-opengraph-work-yearup.png"

import FixedBackground from "../../components/elements/image-background-fixed"
import Layout from "../../components/core/layout"
import PrimaryImageWE from "../../components/work/image-work-yearup"
import Contact from "../../components/contact"
import ShowcaseX from "../../components/elements/image-work-X-yearup"
import PrimaryButton from "../../components/button/primary-button"
import NextPrev from "../../components/button/next-prev-buttons"

// Components

const PrimarySpacer = styled.div`
  margin-top: 200px;
`

const HeaderSpacer = styled.div`
  height: 120px;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const FlexSpacer = styled.div`
  flex-grow: 1;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const SectionHeader = styled.div`
  font-family: "Nitti Bold";
  margin-top: 65px;
  margin-bottom: 35px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`

const SectionContent = styled.div`
  line-height: 1rem;

  ${media.lessThan("medium")`
      width: 300px;
      font-size: 13px;
      line-height:1.1rem;
  `}
`

const WorkClient = styled.div`
  font-family: "Roobert Bold";
  padding-top: 140px;
  font-size: 3.375rem;
  line-height: 2.9rem;
  white-space: nowrap;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 2.1rem;
    line-height: 2rem;
    margin-top: 90px;
    padding-top: 0px;
    width: 200px;
  `}
`

const WorkSubhead = styled.div`
  font-family: "Roobert";
  font-size: 20px;
  margin-top: 20px;
  line-height: 1.1;

  ${media.lessThan("medium")`
        width: 250px;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 14px;
    `}
`

const SectionHorizontalRule = styled.div`
  height: 1px;
  width: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #535663;
  margin-left: auto;
  margin-right: auto;

  ${media.lessThan("medium")`
        margin-top: 0px;
    `}
`

// Main Section

const IndexContainer = styled.div`
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
`

const MainContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.lessThan("medium")`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const MainLeft = styled.div`
  flex-grow: 1;
  max-width: 50%;
  padding-right: 80px;

  ${media.lessThan("medium")`
        padding-right: 0px;
    `}
`

const VerticalRule = styled.div`
  height: 400px;
  width: 1px;
  bottom: 0px;
  background-color: #535663;

  ${media.lessThan("medium")`
    width: 4px;
    display: none;
  `}
`

const HorizontalRule = styled.div`
  display: none;

  ${media.lessThan("medium")`
    display: block;
    width: 4px;
    height: 1px;
    width: 100%;
    margin-top: 80px;
    background-color: #535663;
  `}
`

const MainRight = styled.div`
  display: flex;
  padding-left: 80px;
  padding-top: 20px;
  flex-grow: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: baseline;

  ${media.lessThan("medium")`
        padding-left: 0px;
        padding-top: 0px;
        flex-grow: 0;
        max-width: 90%;
        margin-bottom: 80px;
    `}
`

const SectionImpact = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`

const ImpactContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
`

const ImpactTotal = styled.div`
  font-family: "Roobert Bold";
  font-size: 40px;

  ${media.lessThan("medium")`
        font-size: 26px;
    `}
`

const ImpactDescription = styled.div`
  font-family: "Nitti Bold";
  font-size: 13px;
  line-height: 0.8rem;
  margin-top: 12px;
  max-width: 120px;

  ${media.lessThan("medium")`
        font-family: "Nitti Normal";
        font-size: 11px;
        max-width: 80px;
    `}
`

// Middle Section

const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${media.lessThan("medium")`
        flex-direction: column;
        justify-content: stretch;
    `}
`

const ClientQuote = styled.div`
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  font-size: 40px;
  text-align: center;
  margin-bottom: 45px;
  pointer-events: none;

  ${media.lessThan("medium")`
        font-size: 20px;
        width: 80vw;
        margin-bottom: 25px;
    `}
`

const ClientName = styled.div`
  text-align: center;
  line-height: 1.1;
  pointer-events: none;

  ${media.lessThan("medium")`
        margin-bottom: 80px;
    `}
`

const PhaseDisciplineContainer = styled.ul`
  justify-self: baseline;
`

const PhaseDisciplines = styled.li`
  line-height: 0.5;
  white-space: nowrap;

  ${media.lessThan("1220px")`
    font-size: 12px;
  `}

  ${media.lessThan("medium")`
    font-size: 11px;
  `}
`

// Showcase

const ShowcaseContainer = styled.div`
  display: flex;
  font-family: "Roobert Bold";
  text-align: center;
  font-size: 40px;
  height: 80vh;

  align-items: center;
  justify-content: center;
  margin-top: 80px;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  ${media.lessThan("medium")`
        margin-top: 0px;
        flex-direction: column;
        margin-bottom: 80px;
    `}
`

const ShowcaseSliderDesktop = styled(Slider)`
  width: 800px;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.5);
  mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

  ${media.lessThan("medium")`
        border-radius: 10px;
        width: 90vw;
        height: 0;
        padding-bottom: 62.5%;
        position: relative;
    `}
`

const ShowcaseSliderMobile = styled(Slider)`
  margin-top: 120px;
  margin-left: -40px;
  width: 200px;
  height: 437px;
  border-radius: 16px;
  overflow: hidden;
  mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

  ${media.lessThan("medium")`
        border-radius: 10px;
        height: 410px;
        margin-left: -10px;
        margin-top: 40px;
    `}
`

const ShowcaseSliderShadow = styled.div`
  width: 200px;
  height: 437px;
  border-radius: 16px;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 380px;
  margin-top: 60px;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      display: none;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      display: none;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      display: none;
    }
  }

  ${media.lessThan("medium")`
        border-radius: 10px;
        height: 365px;
        margin-left: -5px;
        margin-top: 118px;
    `}
`

const ProjectCounter = styled.div`
  position: absolute;
  text-align: right;
  font-family: "Roobert SemiBold";
  font-size: 2rem;
  line-height: 1.8rem;
  margin-top: -325px;
  margin-bottom: 2.2rem;
  flex-grow: 1;
  right: 165px;
  pointer-events: none;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-top: -410px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: -410px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: -410px;
    }
  }

  ${media.lessThan("medium")`
    margin-top: ${isAndroid ? -134 : -130}px
    margin-right: 30px;
    font-size: 18px;
    line-height: 1rem;
    margin-top: 20px;
    right: 10px;
  `}
`

// More Work

const MoreClient = styled.div`
  margin-bottom: -20px;
  margin-left: 18px;
  margin-right: 18px;

  ${media.lessThan("medium")`
        margin-bottom: -8px;
    `}
`

const MoreWork = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 80px;
  margin-bottom: -40px;
`

const PreviousWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const WorkSpacer = styled.div`
  flex-grow: 1;
`

const NextWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const ButtonArrow = styled.div`
  font-size: 80px;
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  color: #b8b8b8;

  &:hover {
    color: #b8b8b8;
  }

  ${media.lessThan("medium")`
        font-size: 40px;
    `}
`

class CaseStudy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 1,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
    this.mobileSlider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
    this.mobileSlider.slickPrev()
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
    }
    return (
      <IndexContainer>
        <FixedBackground />
        <PrimaryImageWE />
        <Layout activeSection="work">
          <SEO
            title="Futurehaus: Year Up Case Study"
            description="Enhancing communication and collaboration for staff connecting young adults and employers"
            url="https://futurehaus.studio/work/yearup"
            opengraph={socialOG}
          />
          <MainContainer>
            <MainLeft>
              <PrimarySpacer />
              <WorkClient>Year Up</WorkClient>
              <WorkSubhead>
                Enhancing communication and collaboration for staff connecting
                young adults and employers
              </WorkSubhead>
              <SectionHeader>Where it started</SectionHeader>
              <SectionContent>
                Year Up is a non-profit that connects young adults who need
                opportunity with companies who need their talent. They engaged
                Futurehaus to evaluate new intranet solutions to replace their
                outdated system. They wanted the best tool to facilitate
                communication and collaboration for more than 1000 staff across
                34 geographically dispersed offices.
              </SectionContent>
              <HeaderSpacer />
            </MainLeft>
            <VerticalRule />
            <HorizontalRule />
            <MainRight>
              <FlexSpacer />
              <SectionHeader>Results</SectionHeader>
              <PhaseDisciplineContainer>
                <PhaseDisciplines className="discipline-bullet yearupBrand">
                  Launch of new hub for internal communication <br />
                  <div style={{ marginTop: "10px" }}>
                    and collaboration to connect people
                  </div>
                  <br />
                  <div style={{ marginTop: "10px" }}>
                    across the organization
                  </div>
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet yearupBrand">
                  Conversational interfaces and user-friendly <br />
                  <div style={{ marginTop: "10px" }}>
                    design to simplify daily work
                  </div>
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet yearupBrand">
                  Evaluation of solutions for new website
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet yearupBrand">
                  Coaching to support adoption of
                  <br />
                  <div style={{ marginTop: "10px" }}>agile methodologies</div>
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet yearupBrand">
                  Design thinking practices embraced <br />
                  <div style={{ marginTop: "10px" }}>
                    throughout organization
                  </div>
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
              <SectionHeader>Metrics at a glance</SectionHeader>
              <ImpactContainer>
                <SectionImpact>
                  <div>
                    <ImpactTotal>50%</ImpactTotal>
                    <ImpactDescription>
                      Decrease in duplicate tools
                    </ImpactDescription>
                  </div>
                  <div>
                    <ImpactTotal>5</ImpactTotal>
                    <ImpactDescription>New org roles</ImpactDescription>
                  </div>
                  <div>
                    <ImpactTotal>300</ImpactTotal>
                    <ImpactDescription>
                      Daily <br />
                      active staff
                    </ImpactDescription>
                  </div>
                </SectionImpact>
              </ImpactContainer>
            </MainRight>
          </MainContainer>
          <ShowcaseContainer>
            <ShowcaseX />
            <NextPrev
              color="#ce9127"
              previous={this.previous}
              next={this.next}
            />
            <ProjectCounter>
              <div>0{this.state.currentSlide}</div>
              <div>/03</div>
            </ProjectCounter>
            <ShowcaseSliderDesktop
              ref={c => (this.slider = c)}
              {...settings}
              afterChange={currentSlide => {
                this.setState({ currentSlide: currentSlide + 1 })
              }}
            >
              <div key={1}>
                <img
                  alt="The Welcome to Workplace group, created to support employees adoption and use of the platform."
                  src={desktopA}
                />
              </div>
              <div key={2}>
                <img
                  alt="Chat with the Year Up Assistant, adding and deleting tasks for the day through a chat bot driven experience."
                  src={desktopB}
                />
              </div>
            </ShowcaseSliderDesktop>
            <ShowcaseSliderShadow />
            <ShowcaseSliderMobile
              ref={c => (this.mobileSlider = c)}
              {...settings}
            >
              <div key={1}>
                <img
                  alt="Year Up Assistant, a chat bot, providing the Daily Briefing to an employee, via the Workplace Chat mobile app."
                  src={mobileA}
                />
              </div>
              <div key={2}>
                <img
                  alt="ADP bot providing a summary of an employee's latest pay statement, via the Workplace Chat mobile app."
                  src={mobileB}
                />
              </div>
              <div key={3}>
                <img
                  alt="ADP bot displaying Payroll Deductions by category with a pie chart."
                  src={mobileC}
                />
              </div>
            </ShowcaseSliderMobile>
          </ShowcaseContainer>
          <Fade bottom cascade duration={1250} distance="25px">
            <MiddleContainer>
              <MainLeft style={{ minHeight: 0 }}>
                <SectionHeader>What we did</SectionHeader>
                <SectionContent>
                  We quickly determined that Year Up staff were using nearly 80
                  different systems and tools, 50 of which were communication or
                  collaboration focused, to address their diverse business
                  needs. This “shadow IT” was not efficient, reliable, or safe;
                  it actually discouraged staff collaboration.
                  <br />
                  <br />
                  We conducted in-depth research and led several workshops to
                  identify how to design a one-stop, internal communications hub
                  that would meet the needs of the staff. Collaboratively, we
                  selected as the primary tool{" "}
                  <a
                    href="https://www.facebook.com/workplace"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Workplace
                  </a>
                  , a Facebook product that is intuitive to use and offers
                  messaging capability. We set up a conversational interface by
                  partnering with Kore.ai, a bot platform that uses machine
                  learning and natural language processing. As an added benefit,
                  this advanced interface enabled connectivity between Year Up’s
                  new Workplace hub and other, existing organizational tools. We
                  also trained their internal staff on how to manage and further
                  develop this powerful capacity. Our overall solution did not
                  require us to build from scratch an expensive, custom tool. We
                  released the new hub in phases to maximize staff adoption and
                  engagement.
                  <br />
                  <br />
                  We trained Year Up staff on our design thinking approach and
                  helped identify roles, job descriptions, and an operational
                  process for their new, in-house Innovation Lab. The Lab will
                  continue the work of refining the hub and enable Year Up to
                  anticipate and address future needs internally and
                  collaboratively.
                </SectionContent>
                <PrimaryButton
                  blank
                  topSpacing
                  backgroundImage={linkBackgroundImage}
                  link="https://www.yearup.org"
                >
                  Learn about Year Up
                </PrimaryButton>
              </MainLeft>
              <HorizontalRule />
              <MainRight>
                <SectionHeader>Services</SectionHeader>
                <PhaseDisciplineContainer>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Design, technology, and innovation strategy
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Communication and change management strategy
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Design and innovation workshop facilitation
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Research, including stakeholder
                    <br />
                    <div style={{ marginTop: "10px" }}>
                      and expert interviews
                    </div>
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Prototyping
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    User experience and visual design
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Agile product development and implementation
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Launch and product support
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Adoption and engagement campaign
                  </PhaseDisciplines>
                  <PhaseDisciplines className="discipline-bullet yearupBrand">
                    Internal product team training
                  </PhaseDisciplines>
                </PhaseDisciplineContainer>
                <SectionHeader>In collaboration with</SectionHeader>
                <SectionContent>
                  <a
                    href="http://www.11ratio.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    1:1 Ratio
                  </a>
                </SectionContent>
              </MainRight>
            </MiddleContainer>
          </Fade>
          <SectionHorizontalRule />
          <Fade bottom cascade duration={1250} distance="25px">
            <ClientQuote>
              “Futurehaus injects the best of innovative design thinking into an
              organization and I highly recommend them for their level of
              professionalism and expertise.”
            </ClientQuote>
            <ClientName>
              Mary Barton-Rau, Senior Director Application Enablement
              <br />
              Year Up
            </ClientName>
          </Fade>
          <Contact small backgroundImage={linkBackgroundImage} />
          <MoreWork>
            <Fade left distance="20px">
              <AniLink
                to="/work/cleancio"
                cover
                direction="left"
                bg="#222533"
                className="caseLink"
              >
                <PreviousWork>
                  <ButtonArrow>←</ButtonArrow>
                  <MoreClient>Cleancio</MoreClient>
                </PreviousWork>
              </AniLink>
            </Fade>
            <WorkSpacer />
            <Fade right distance="20px">
              <AniLink
                to="/work/exelon"
                cover
                direction="right"
                bg="#222533"
                className="caseLink"
              >
                <NextWork>
                  <MoreClient>Exelon</MoreClient>
                  <ButtonArrow>→</ButtonArrow>
                </NextWork>
              </AniLink>
            </Fade>
          </MoreWork>
        </Layout>
      </IndexContainer>
    )
  }
}

export default CaseStudy
